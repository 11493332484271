import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Reaction from './Reaction';
import axios from 'axios';
import CardCommentPost from './CardCommentPost';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import $ from "jquery";
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import env from '../../environments/Api';



const root = env('posts').api.globalRoute;


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 1500,
  },
  root: {
    '& > *': {
      important: true,
      maxWidth: 900,
    },
  },
  iconComment: {
    backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/comentario_icono_post.svg)`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minWidth: 200,
  },
  media: {
    maxWidth: '100%',
    margin: 15,
    paddingTop: '56.25%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/comentario_icono_post.svg)`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
    backgroundRepeat: 'no-repeat',
    minWidth: 30,
    minHeight: 30,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  avatar: {
    backgroundColor: grey[200],
  },
  textField: {
    marginLeft: 15,
    margin: 10,
  },
  typography: {
    fontSize: 18,
    color: 'black'
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    margin: theme.spacing(1),
  },
  container: {
    display: 'inline',
  },
  button: {
    backgroundColor: '#0A9DBF',
    color: '#ffffff',
    "&:focus,&:hover,&$active": {
      backgroundColor: '#192340',
      color: '#0A9DBF'
    }
  }
}));


function CardMainPost(props) {
  const classes = useStyles();
  const [listPostComment, setListPostComment] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [message, setMessage] = useState();
  const [listCollaboratorAttachComment, setListCollaboratorAttachComment] = useState([]);
  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
    openDrop: false,
  });
  const [quantityComment, setQuantityComment] = useState(props.quantityComment);

  const updateQuantityComment = () => {
    var temp = quantityComment - 1;
    setQuantityComment(temp)
  }

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const handleExpandClick = () => {
    axios
      .get(`${root}/${props.idPost}/comentarios`)
      .then(res => {
        if (res.status == 200) {
          setListPostComment(res.data)
          setExpanded(!expanded)
        }
      })

  };

  const removePost = () => {
    var x = props.idPost;
    axios
      .delete(`${root}/${props.idPost}/${props.userId}`)
      .then(res => {
        if (res) {
          $("#" + props.idPost).remove();
        }
      })
  }

  const createComment = () => {
    if (message == null || message == "") {
      setState({
        open: true,
        Transition: SlideTransition,
        message: "Es necesario escribir algo para poder comentar el post.",
      });
    } else {
      listCollaboratorAttachComment.push(props.idOwnerPost);
      axios
        .post(`${root}/comentarios`,
          {
            id_post_vinculado: props.idPost,
            id_colaborador: props.userId,
            descripcion: message,
            lista_colaboradores: listCollaboratorAttachComment,
          })
        .then(
          res => {
            if (res.status == 200) {
              listCollaboratorAttachComment.pop();
              axios
                .get(`${root}/${props.idPost}/comentarios`)
                .then(res => {
                  if (res.status == 200) {
                    setListPostComment(res.data)
                    setMessage("")
                    var temp = quantityComment + 1;
                    setQuantityComment(temp)
                    
                  }
                })
            }
          }
        )
        .catch(console.log);
    }
  }

  const validatePostUser = (postIdOwner) => {
    if (postIdOwner == props.userId) {
      return true
    } else {
      return false
    }
  }

  function avatarLink() {
    if (props.avatarUrl == null) {
      return "https://storage.googleapis.com/fundes-gestion-talento/imagenes/perfiles/none.jpg"
    } else {
      return props.avatarUrl
    }
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            src={avatarLink()}
            className={classes.avatar} />
        }
        action={
          validatePostUser(props.idOwnerPost) && (
            <Tooltip title="Delete">
              <IconButton aria-label="settings" onClick={removePost}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        }
        title={
          <strong>{props.name}</strong>
        }
        subheader={
          props.date
        }
      />
      <CardContent>
        <Snackbar
          open={state.open}
          autoHideDuration={2500}
          onClose={handleClose}
          TransitionComponent={state.Transition}
          message={state.message}
        />
        <Typography >
          <label>¡Felicidades </label>
          <label ><strong>{props.congratUser.join(", ")}</strong>!</label>
        </Typography>
        <Typography >
          <span> Has recibido un <IconButton
            style={{
              minWidth: 50,
              minHeight: 50,
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(
                                  https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fundes_star_color_icono_post_icono_crear_post_512x512.png
                                  )`,
              backgroundPosition: 'center center',
              backgroundSize: 50,
            }}
          />
          </span>
          {props.typeRecognition != 'Otra' && (
            <label>  &nbsp; en <strong>{props.typeRecognition}</strong></label>
          )
          }
          .
          </Typography>
        <br />
        <Typography variant="body2" component="p" className={classes.typography}>
          {props.comment}
        </Typography>
        {props.imageUrl && (
          <div
            style={
              {
                padding: 10,
              }
            }>
            <br />
            <img
              src={props.imageUrl}
              width="100%"
              height="100%"
              alt=""
              style={{ display: "block", borderRadius: 16 }}
            />
          </div>
        )}
      </CardContent>
      <CardActions >
        <Hidden>
          <Tooltip title="Reacciones">
            <IconButton aria-label="add to favorites">
              <Reaction userid={props.userId} idPost={props.idPost} reactions={props.reactions} listReactions={props.listReactions}/>
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden >
          <div onClick={handleExpandClick}>
            <label>{quantityComment}</label>
            <Tooltip title="Comentarios">
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}

                aria-expanded={expanded}
                aria-label="show more"
              />
            </Tooltip>
            <label style={{ color: 'black', fontSize: 'medium' }}>Comentar</label>
          </div>
        </Hidden>
        {/* </div> */}

      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit >
        <br />
        <div id="comments">
          <FormControl fullWidth className={classes.margin} variant="outlined">
            {listPostComment.map((reply) => {
              return (
                <React.Fragment>
                  <br />
                  <div id={reply.id_post} class="comment">
                    <CardCommentPost
                      key={reply.id_post}
                      idPost={reply.id_post}
                      userId={props.userId}
                      name={reply.nombre_colaborador}
                      comment={reply.descripcion}
                      date={reply.diferencia_tiempo_minutos}
                      idOwnerComment={reply.id_colaborador}
                      avatarUrl={reply.imagen_url_perfil}
                      reactions={props.reactions}
                      onUpdateQuantity={updateQuantityComment}
                      listReactions={reply.lista_reacciones}
                    />
                  </div>
                </React.Fragment>
              );
            })}
            <div class="textAreaComment">
              <FormControl fullWidth className={classes.margin} variant="outlined">
                <TextField
                  value={message}
                  className={classes.textField}
                  id="outlined-multiline-static"
                  label="Comentar"
                  multiline
                  rows="4"
                  variant="outlined"
                  onChange={(e) =>
                    setMessage((e.target.value))}
                />
              </FormControl>
            </div>
            <Box display="flex" justifyContent="flex-end" m={1} p={1} bgcolor="background.paper">
              <Box p={1} bgcolor="background.paper">
                <Button
                  id="sendB"
                  onClick={createComment}
                  variant="contained"
                  size="small"
                  className={classes.button}
                  startIcon={<SendIcon />}
                >
                  Enviar
          </Button>
              </Box>
            </Box>
          </FormControl>
        </div>
      </Collapse>
    </Card>

  );
}

export default CardMainPost;