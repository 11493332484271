import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ShowGlossaryList from '../components/ShowGlossaryList';
import env from '../../environments/Api';
import Progress from '../components/ProgressRequest';
import ReactGA from 'react-ga';


const root = env('dictionarys').api.globalRoute;

class GlossaryPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            personas: [],
            isFecth: true,
        }
    }

    componentDidMount() {
        ReactGA.pageview(`${this.props.location.pathname}`);
        fetch(`${root}`)
            .then(res => res.json())
            .then((data) => {
                this.setState({ personas: data, isFecth: false })
            })
            .catch(console.log)
    }

    render() {
        return (
            <div>
                <Header
                    userMail={localStorage.getItem('emailCollaborator')}
                    userId={localStorage.getItem('idCollaborator')}
                    userName={localStorage.getItem('nameCollaborator')}
                />
                <br />
                <br />
                <ShowGlossaryList dictionaryData={this.state.personas} />
                <br />
                <br />
                {
                    this.state.isFecth && (
                        <Progress />
                    )
                }
                <br />
                <Footer
                    pageSelect={'glossary'}
                    userId={localStorage.getItem('idCollaborator')}
                    userName={localStorage.getItem('nameCollaborator')}
                    userMail={localStorage.getItem('emailCollaborator')}
                />
            </div>
        );
    }
}

export default GlossaryPage;
