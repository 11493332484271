import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 1000,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      important: true,
      maxWidth: 900,
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    height: 50,
    width: 50
  },
}));


function CardGlossary(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (

    <Card className={classes.card}>
      <CardHeader
          avatar={
            <Avatar
              src={props.recognitionAvatar}
              className={classes.avatar} 
              />
          }
        title={
          <Typography variant="h6" gutterBottom>
          {props.name}
        </Typography>
        }
      />
      <CardContent>
        <Typography variant="body2" component="p">
          {props.description}
        </Typography>
      </CardContent>
    </Card>

  );
}

export default CardGlossary;