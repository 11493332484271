import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    right: 0,
    left: 0,
    backgroundColor: '#192340',
    height: 70,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  appBar: {
    height: 60,
    index: 'auto',
    position: 'fixed',
  },
  title: {
    flexGrow: 1,
  }, grow: {
    flexGrow: 1,
  },
  avatar: {
    width: 140,
    height: 70,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 5,
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));

function Header(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.clear();
    //window.location.reload();
    // var url = `https://www.google.com`;
    // window.location.replace(url);
  }

  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Account" tooltip="Account" >
          <Avatar
            variant="rounded"
            src={`https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fundes_star_blanco_header_512x512.png`}
            className={classes.avatar} />
        </IconButton >
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Button
              style={{
                backgroundColor: '#0A9DBF',
                color: '#ffffff'
              }}
              component={Link}
              to={'/fundespeople/login'}
              variant="text"
              onClick={logOut}
            >Cerrar Sesión</ Button>
          </MenuItem>
        </Menu>
        <div className={classes.grow} />
        <IconButton edge="end" color="inherit" onClick={handleMenu}>
          <MoreIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;