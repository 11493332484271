import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CardCreatePost from '../components/CardCreatePost';


class CreatePostPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <Header
          userMail={localStorage.getItem('emailCollaborator')}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        <br />
        <br />
        <br />
        <br />
        <CardCreatePost
          userMail={localStorage.getItem('emailCollaborator')}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        <br />
        <br />
        <br />
        <Footer
          pageSelect={'home'}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
          userMail={localStorage.getItem('emailCollaborator')}
        />
      </div>
    );
  }
}

export default CreatePostPage;
