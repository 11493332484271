export function dateToShow(minutes) {
    var showdate = "Hace ";
    var value;
    if(minutes == 0){
        showdate = 'Nuevo';
    }else if(minutes < 60){
        value = minutes;
        if (value > 1) {
            showdate = showdate + value + " minutos";
        } else {
            showdate = showdate + value + " minuto";
        }
    }else if(minutes < 1440){
        value = Math.round((minutes / 60));
        if (value > 1) {
            showdate = showdate + value + " horas";
        } else {
            showdate = showdate + value + " hora";
        }
    }else if(minutes < 43200){
        value = Math.round((minutes / 1440));
        if (value > 1) {
            showdate = showdate + value + " días";
        } else {
            showdate = showdate + value + " día";
        }
    }else if(minutes < 518400){
        value = Math.round((minutes / 43800));
        if (value > 1) {
            showdate = showdate + value + " meses";
        } else {
            showdate = showdate + value + " mes";
        }
    }else {
        showdate = showdate + "más de 1 año";
    }
    return showdate;
}
