import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ShowPostList from '../components/ShowPostList';
import FabButtonScrollTop from '../components/FabButtonScrollTop';
import env from '../../environments/Api';
import Progress from '../components/ProgressRequest';
import CardLinkToCreatePage from '../components/CardLinkToCreatePage';
import ReactGA from 'react-ga';

// ReactGA.initialize('UA-159149691-1');
// ReactGA.set('page','/FundesStar/home');
// ReactGA.send('pageview');

//ReactGA.pageview(`/FundesStar/home`);

const root = env('posts').api.globalRoute;


class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      post: [],
      countPage: 1,
      loadingState: true,
      noAuthorized: '',
    }
  }

  firstTime() {
    fetch(`${root}/${this.state.countPage}/post-felicitaciones`)
      .then(res => res.json())
      .then((data) => {
        this.setState({ post: data, loadingState: false })
      })
      .catch(console.log)
  }

  loadMoreItems() {
    var itemsScroll = this.state.countPage++;
    if (this.state.loadingState) {
      return;
    } else {
      this.setState({ loadingState: true });
      setTimeout(() => {
        fetch(`${root}/${this.state.countPage}/post-felicitaciones`)
          .then(res => res.json())
          .then((data) => {
            this.setState({ post: data, loadingState: false })
          })
          .catch(console.log)
      }, 500);
    }
  }

  componentDidMount() {
    ReactGA.pageview(`${this.props.location.pathname}`);
    this.firstTime();
    window.addEventListener('scroll', this.handleOnScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
  };

  handleOnScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      this.loadMoreItems();
    }
  };

  render() {
    return (
      < div style={{ background: 'transparent' }
      }>
        <Header
          userMail={localStorage.getItem('emailCollaborator')}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        <br />
        <br />
        <br />
        <CardLinkToCreatePage />
        <ShowPostList
          listPost={this.state.post}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        <h1>{process.env.REACT_APP_TEXTO}</h1>
        <FabButtonScrollTop
          userMail={localStorage.getItem('emailCollaborator')}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        {
          this.state.loadingState && (
            <Progress />
          )
        }
        <br />
        <Footer
          pageSelect={'home'}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
          userMail={localStorage.getItem('emailCollaborator')}
        />
      </div >
    );
  }
}


export default HomePage;
