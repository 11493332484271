import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import Login from './fundespeople/pages/Login';
import HomePage from './fundesstar/pages/HomePage';
import ProfilePage from './fundesstar/pages/ProfilePage';
import NotificationPage from './fundesstar/pages/NotificationPage';
import GlossaryPage from './fundesstar/pages/GlossaryPage';
import CreatePostPage from './fundesstar/pages/CreatePostPage';
import InformationPage from './fundesstar/pages/InformationPage';
import WelcomePage from './fundespeople/pages/WelcomePage'
// import ReactGA from 'react-ga';
// ReactGA.pageview(`${this.props.location.pathname}`);
//var accept = await axios.get(`${root}/${localStorage.getItem('emailCollaborator')}/validacion-permiso-vistas`)

// import axios from "axios";
// import env from './environments/Api';


// const root = env('profiles').api.globalRoute;

// const ValidationRedirectUser = axios.get(`${root}/${localStorage.getItem('emailCollaborator')}/validacion-permiso-vistas`);

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem("tokenCollaborator") ? (<Component {...props} />) : 
                (
                    <Redirect to={{
                        pathname: "/fundespeople/login",
                        state: { from: props.location }
                    }}
                    />
                )
        }
    />
);

const createRedirect = to => () => <Redirect to={to} />

const Routes = (...props) => {
    return (
        <Switch>
            {/* *****Rutas Fundes People***** */}

            <Route exact path='/fundespeople/login' render={props => <Login {...props} />} />
            {/* <ProtectedRoute exact path="/" component={createRedirect('/fundespeople')} /> */}
            {/* <ProtectedRoute exact path="/fundespeople" component={WelcomePage} /> */}

            {/* *****Rutas Fundes Star***** */}
            <ProtectedRoute exact path="/" component={createRedirect('/fundesstar/home')} /> {/*Esta linea es solo para cuando no se trabaja en la super app, si se quiere super app es descomentar la de fundes people*/}
            <ProtectedRoute exact path="/fundesstar/home" component={HomePage} />
            <ProtectedRoute exact path='/fundesstar/profile' component={ProfilePage} />
            <ProtectedRoute exact path='/fundesstar/notification' component={NotificationPage} />
            <ProtectedRoute exact path='/fundesstar/glossary' component={GlossaryPage} />
            <ProtectedRoute exact path='/fundesstar/createpage' component={CreatePostPage} />
            {/* <ProtectedRoute path='/information' component={InformationPage} /> */}
        </Switch>
    );
}

export default Routes;