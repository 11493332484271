import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom'



const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 1500,
        backgroundColor: '#fffff'
    },
    root: {
        '& > *': {
            important: true,
            maxWidth: 900,
        },
    },
    starIcon: {
        minWidth: 50,
        minHeight: 50,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/star_icon_crear_reconocimiento.svg)`,
        backgroundPosition: 'center center',
        backgroundSize: 48,
    },
    plusIcon: {
        minWidth: 50,
        minHeight: 50,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/plus_icon_crear_reconocimiento.svg)`,
        backgroundPosition: 'center center',
        backgroundSize: 48,
    },
    info: {
        marginTop: 50,
        position: 'static',
        margin: 'auto',
        alignItems: 'center',
        textAlign: 'center',
    },
}));


function CardLinkToCreatePage(props) {
    const classes = useStyles();

    return (
        <Container
            component={Link}
            to={'/fundesstar/createpage'}
            style={{ textDecorationLine: 'none' }}
        >
            <Card className={classes.card}
            >
                <CardContent
                    style={{
                        marginTop: 50,
                        position: 'static',
                        margin: 'auto',
                        alignItems: 'center',
                        textAlign: 'center',
                        autoLine: 'disable'
                    }}
                >
                    <span> ¡Haz un reconocimiento aquí!
                         <IconButton
                            className={classes.starIcon}
                        /></span>
                </CardContent>
            </Card>
        </Container>

    );
}

export default CardLinkToCreatePage;
