import React, { Component } from 'react';
import './App.css';
import RouteAll from './RoutesAll';
import axios from "axios";
import env from './environments/Api';


const root = env('profiles').api.globalRoute;


class App extends Component {
    constructor(props) {
      super(props)
      this.state = {
        active : false,
      }
  }

  componentDidMount(){
    this.activeUser()
  }

  activeUser() {
    axios
    .get(`${root}/${localStorage.getItem('emailCollaborator')}/validacion-permiso-vistas`)
      .then(res => {
        if (res.status == 200) {
          console.log(localStorage.getItem('tokenCollaborator'))
          this.setState({ active: res.data})
        }
      }
      )
  }


  render() {
    if(localStorage.getItem('tokenCollaborator') == null || this.state.active){
      return (
        <RouteAll />
      );
     }
    else{
      return <div></div>
    }


  }
}

export default App;