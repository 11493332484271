import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import env from '../../environments/Api';
import InformationContent from '../components/InformationContent';


const root = env('posts').api.globalRoute;


class InformationPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }



    render() {
        return (
            < div style={{ background: 'transparent' }}>
                <Header
                    userMail={localStorage.getItem('emailCollaborator')}
                    userId={localStorage.getItem('idCollaborator')}
                    userName={localStorage.getItem('nameCollaborator')}
                />
                <br />
                <br />
                <br />
                <InformationContent />
                <br />
                <br />
                <br />
                <Footer
                    // pageSelect={'home'}
                    userId={localStorage.getItem('idCollaborator')}
                    userName={localStorage.getItem('nameCollaborator')}
                    userMail={localStorage.getItem('emailCollaborator')}
                />
            </div >
        );
    }
}


export default InformationPage;
