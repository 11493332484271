import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { Container } from '@material-ui/core';
import SliderRecognition from './SliderRecognition';
import env from '../../environments/Api';

const root = env('profiles').api.globalRoute;

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 1500,
  },
  cardHeader: {
    minHeight: 0,
  },
  image: {
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/circulo_entero_card_reconocimiento.svg
      )`,
    backgroundSize: '100%',
    backgroundSize: 'cover',
    marginLeft: -50,
    marginRight: -50,
    backgroundPositionY: '95%',
    minHeight: 100,
  },
  avatar: {
    width: 150,
    height: 150,
    position: 'absolute',
    marginTop: -70,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));

const setNullRecognition = (value) => {
  if (value == null) {
    return 'Sin Trofeo'
  } else {
    return value
  }
}

function CardRecognition(props) {
  const classes = useStyles();
  const [finish, setFinish] = useState(false);

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    adaptiveHeight: true,
    duration: 300,
  };

  const fullSlide = (recognition) => {
    return recognition.map((reco) => {
      return (
        <div>
          <SliderRecognition
            trophyRecognition={setNullRecognition(reco.trofeo_reconocimiento)}
            quantityRecognition={reco.cantidad_reconocimiento}
            typeRecognition={reco.tipo_reconocimiento}
          />
        </div>
      );
    })
  }


  return (
    <Container justify="center"
      alignItems="center">
      <Card className={classes.card}>
        <CardHeader
          className={
            classes.image
          }
          avatar={
            <Avatar
              variant="rounded"
              src={`https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fundes_star_blanco_card_reconocimiento.png`}
              className={classes.avatar}
            />
          }
          image={
            <CardMedia
              className={classes.image}
              image={
                "https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/circulo_entero_card_reconocimiento.svg"
              }
            />}
        />
        <CardContent>
          {
            props.recognition.map((reco) => {
              return (
                <SliderRecognition
                  trophyRecognition={setNullRecognition(reco.trofeo_reconocimiento)}
                  quantityRecognition={reco.cantidad_reconocimiento}
                  typeRecognition={reco.tipo_reconocimiento}
                />
              );
            })
          }
        </CardContent>
      </Card>
    </Container>
  );
}

export default CardRecognition;