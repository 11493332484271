import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);

var OneSignal = window.OneSignal || [];

OneSignal.push(function () {
  OneSignal.init(
    {
    appId: `${process.env.REACT_APP_ONE_SIGNAL_ID}`,
    autoResubscribe: true,
    notifyButton: {
      enable: true,
    },
  });
  OneSignal.showNativePrompt();
});

ReactDOM.render(
    <BrowserRouter forceRefresh={true}><App /></BrowserRouter>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();
