import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    boxProgress: {
        background: 'transparent',
        position: 'fixed',
        top: '50%',
        left: '50%',
        right: '50%',
    },
}));

function Progress() {
    const classes = useStyles();
    return (
        <Box display="flex" justifyContent="center" m={1} p={1} className={classes.boxProgress} >
            <Box p={1} style={{ background: 'transparent' }}>
                <Fab >
                    <CircularProgress />
                </Fab>
            </Box>
        </Box>

    );
}

export default Progress;