import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Player } from 'video-react';
import "video-react/dist/video-react.css"; // import css
import ReactPlayer from 'react-player';
import { Hidden, Divider } from '@material-ui/core';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function SimpleTabs() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root} style={
            {
                backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/patron_card_perfil.jpg)`,
                color: '#ffffff'
            }
        }>
            <AppBar position="static"
                style={{
                    backgroundColor: '#0A9DBF'
                }}>
                <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
                    <Tab label="Como utilizar FUNDES Star" {...a11yProps(0)} />
                    <Tab label="Anuncios Colectivos" {...a11yProps(1)} />
                    <Tab label="Notificaciones Colectivas" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Player>
                    <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
                    {/* <source src="https://storage.googleapis.com/fundes-gestion-talento/videos/pruebas/local/2020-02-20_15-27-59.mp4" /> */}
                </Player>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography variant="h4" gutterBottom align='center'>
                    FUNDES Star
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Guía de usuario
                </Typography>
                <Typography variant="body2" gutterBottom>
                    El siguiente video corresponde a como utilizar la aplicación FUNDES Star, con las funcionalidades y caracteristicas de la aplicación,
                    la naturaleza de la aplicación será la misma en todo momento, sin embrago la aplicación podría sufrir cambios a futuro
                    de diseño. En la sección 'Anuncios Colectivos' se anunciaran los futuros cambios
                    para mantenerlos actualizados al respecto.
                    Gracias!
                </Typography>
                <ReactPlayer
                    width={'100%'}
                    controls={true}
                    loop={true}
                    url='https://www.youtube.com/watch?v=k7brvKUDZ0Y'
                    playing />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Typography variant="h5" gutterBottom align='center'>
                    🤩¡Cambios Nuevos!🤩
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Ahora puedes ver quien reacciona a tu post.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Si quieres ver quien ha reaccionado a tu post solo debes apretar alguno de los emojis,
                    esto te mostrara quienes han reaccionado.
                </Typography>
                <div style=
                {{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}>
                    <Hidden>
                        <div>
                            <img
                                src='https://storage.googleapis.com/fundes-gestion-talento/videos/pruebas/local/EmojiSeleccionadoAnuncios.png'
                                width="100%"
                                height="100%"
                                alt=""
                                style={{ display: "block", borderRadius: 16 }}
                            />
                        </div>
                    </Hidden>
                    <Hidden>
                        <div>
                            <img
                                src='https://storage.googleapis.com/fundes-gestion-talento/videos/pruebas/local/MostrarModalReacciones.png'
                                width="100%"
                                height="100%"
                                alt=""
                                style={{ display: "block", borderRadius: 16 }}
                            />
                        </div>
                    </Hidden>
                </div>
           <br />
           <Divider style={{backgroundColor: '#ffffff'}}/>
            </TabPanel>
        </div>
    );
}