import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import axios from 'axios';


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 1500,
    minHeight: 450,
  },
  imageBackground: {
    backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/patron_card_perfil.jpg)`,
    color: 'white',
    backgroundSize: 'cover',
    minHeight: 260,
  },
  infoUser: {
    marginTop: 50,
    position: 'static',
    margin: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  avatar: {
    width: 140,
    height: 140,
    position: 'absolute',
    borderTopColor: '#fffff',
    top: 280,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));

function ProfileCard(props) {
  const classes = useStyles();

  return (
    <Container justify="center"
      alignItems="center">
      <Card className={classes.card}>
        <CardHeader
          className={classes.imageBackground}
          avatar={
            <Avatar
              src={props.userAvatar}
              className={classes.avatar} />
          }
        />
        <CardContent className={classes.infoUser}>
          <Typography variant="body5" component="h1">
            {props.fullNameProfile}
          </Typography>
          <Typography variant="body4" component="h2">
            {props.positionProfile}
          </Typography>
          <Typography variant="body3" component="h3">
            {props.countryProfile}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ProfileCard;