import CardGlossary from './CardGlossary';
import React, { Fragment } from 'react';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';

function ShowGlossaryList(props) {
    return (
        <Fragment>
            <Container justify="center"
                alignItems="center">
                <br />
                <br />
                {props.dictionaryData.map((reconocimiento) => {
                    return <Box p={1}> <CardGlossary
                        idRecognition={reconocimiento.id_tipo_reconocimiento}
                        name={reconocimiento.nombre}
                        description={reconocimiento.descripcion}
                        recognitionAvatar={reconocimiento.url_imagen_reconocimiento}
                    /> </Box>
                })}
            </Container>
        </Fragment>


    );
}

export default ShowGlossaryList;