import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Profilecard from '../components/ProfileCard';
import ShowPostList from '../components/ShowPostList';
import CardRecognition from '../components/CardRecognition';
import env from '../../environments/Api';
import Progress from '../components/ProgressRequest';
import ReactGA from 'react-ga';

const root = env('posts').api.globalRoute;
const secondRoot = env('profiles').api.globalRoute;

class ProfilePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listPostCollaborator: [],
      user: [],
      countPage: 1,
      recognition: [],
      loadingState: true
    }
  }


  async profileInfo() {
    await fetch(`${secondRoot}/${localStorage.getItem('idCollaborator')}`)
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())
      .then(res => { this.setState({ user: res }) })

  }

  async myPostRecognition() {
    await fetch(`${root}/colaboradores/${localStorage.getItem('idCollaborator')}/posts/${this.state.countPage}`)
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())
      .then(res => { this.setState({ listPostCollaborator: res, loadingState: false }) })
  }

  async trophieRecognition() {
    await fetch(`${secondRoot}/${localStorage.getItem('idCollaborator')}/reconocimientos`)
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())
      .then(res => { this.setState({ recognition: res }) })
  }


  async firstTime() {
    await this.profileInfo();
    await this.myPostRecognition();
    await this.trophieRecognition();
  }

  loadMoreItems() {
    var itemsScroll = this.state.countPage++;
    if (this.state.loadingState) {
      return;
    } else {
      this.setState({ loadingState: true });
      setTimeout(() => {
        fetch(`${root}/colaboradores/${localStorage.getItem('idCollaborator')}/posts/${this.state.countPage}`)
          .then(res => res.json())
          .then((data) => {
            this.setState({ listPostCollaborator: data, loadingState: false })
          })
          .catch(console.log)
      }, 500);
    }
  }

  componentDidMount() {
    ReactGA.pageview(`${this.props.location.pathname}`);
    this.firstTime();
    window.addEventListener('scroll', this.handleOnScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
  };

  handleOnScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom) {
      this.loadMoreItems();
    }
  };

  render() {
    return (
      <div>
        <Header
          userMail={localStorage.getItem('emailCollaborator')}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        <br />
        <br />
        <br />
        <br />
        <Profilecard
          idProfile={this.state.user.id_colaborador}
          fullNameProfile={this.state.user.nombre_completo}
          emailProfile={this.state.user.email}
          areaProfile={this.state.user.area}
          countryProfile={this.state.user.pais}
          companyProfile={this.state.user.compania}
          positionProfile={this.state.user.puesto}
          typeProfile={this.state.user.tipo_colaborador}
          userAvatar={this.state.user.url_imagen_colaborador}
        />
        <br />
        {this.state.recognition && (
          <CardRecognition
            recognition={this.state.recognition}
          />
        )
        }

        <br />
        <ShowPostList page="My posts"
          listPost={this.state.listPostCollaborator}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
        />
        <br />
        <br />
        {
          this.state.loadingState && (
            <Progress />
          )
        }
        <br />
        <Footer pageSelect={'profile'}
          userId={localStorage.getItem('idCollaborator')}
          userName={localStorage.getItem('nameCollaborator')}
          userMail={localStorage.getItem('emailCollaborator')}
        />
      </div>
    );
  }
}

export default ProfilePage;
