import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link, Redirect } from 'react-router-dom';
import { Component } from 'react';


const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    right: 0,
    left: 0,
    bottom: 0,
    borderRadius: 20,
  },
  home: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/home_sin_pintar_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  homeSelected: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
        https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/home_pintado_footer_32x32.svg
        )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  notification: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/notificaciones_sin_pintar_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  notificationSelected: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/notificaciones_pintado_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  glossary: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/glosario_sin_pintar_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  glossarySelected: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/glosario_pintado_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  profile: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/perfil_sin_pintar_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  profileSelected: {
    minWidth: 30,
    minHeight: 30,
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(
      https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/perfil_pintado_footer_32x32.svg
      )`,
    backgroundPosition: 'center center',
    backgroundSize: 26,
  },
  badge: {
    color: '#0A9DBF'
  }
}));


export default function Footer(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.pageSelect);

  const createRedirect = to => () => <Redirect to={to} />


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <BottomNavigation value={value} onChange={handleChange}
      className={classes.root}
    >
      <BottomNavigationAction
        value="home"
        component={Link}
        to={'/fundesstar/home'}
        classes={
          { root: classes.home, selected: classes.homeSelected }
        }
        onClick={() => {
          var now = `${window.location}`;
          var permission = now.includes("createpage");
          if (value == 'home' && !permission) {
            window.location.reload('')
            //createRedirect('/fundesstar/home')
          }
        }}
      />
      {/* <Badge
       className={classes.badge}
       color='secondary'
        badgeContent={1000}
        max={10}> */}
      <BottomNavigationAction
        value="notification"
        component={Link}
        to={'/fundesstar/notification'}
        classes={
          { root: classes.notification, selected: classes.notificationSelected }
        }
        onClick={() => {
          if (value == 'notification') {
            window.location.reload('')
          }
        }}
      />
      {/* </Badge> */}
      <BottomNavigationAction
        value="glossary"
        component={Link}
        to={'/fundesstar/glossary'}
        classes={
          { root: classes.glossary, selected: classes.glossarySelected }
        }
        onClick={() => {
          if (value == 'glossary') {
            window.location.reload('')
          }
        }}
      />
      <BottomNavigationAction
        value="profile"
        component={Link}
        to={'/fundesstar/profile'}
        classes={
          { root: classes.profile, selected: classes.profileSelected }
        }
        onClick={() => {
          if (value == 'profile') {
            window.location.reload('')
          }
        }}
      />
    </BottomNavigation>
  );
}