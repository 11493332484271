import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Card } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    infoRecognition: {
        marginTop: 20,
        position: 'static',
        margin: 'auto',
        alignItems: 'center',
        textAlign: 'center',
    },
    bronze: {
        minWidth: 40,
        minHeight: 40,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(
            https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/estrella_bronce_reconocimiento_32x32.svg
          )`,
        backgroundPosition: 'center center',
        backgroundSize: 35,
    },
    silver: {
        left: 0,
        right: 0,
        minWidth: 40,
        minHeight: 40,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(
            https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/estrella_plata_reconocimiento_32x32.svg
          )`,
        backgroundPosition: 'center center',
        backgroundSize: 35,
    },
    gold: {
        right: 0,
        minWidth: 40,
        minHeight: 40,
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(
            https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/estrella_oro_reconocimiento_32x32.svg
          )`,
        backgroundPosition: 'center center',
        backgroundSize: 35,
    },
    sliderFormat: {
        color: '#192340',
    }
}));


const PrettoSlider = withStyles({
    root: {
        color: "#0A9DBF",
        height: 8
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus,&:hover,&$active": {
            boxShadow: "inherit"
        }
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)"
    },
    track: {
        height: 8,
        borderRadius: 4
    },
    rail: {
        height: 4,
        borderRadius: 4
    }
})(Slider);


function recognitionStart(value) {

    var level = 0;
    if (value <= 4) {
        level = 0;
    } else if (value > 4 && value <= 9) {
        level = 1;
    } else if (value > 9 && value <= 14) {
        level = 2;
    } else {
        level = 3;
    }
    return level;
}

export default function SliderRecognition(props) {
    const classes = useStyles();
    return (
        <Card style={{ padding: 2, margin: 2 }}>
            <Box component="fieldset" mb={3} borderColor="transparent" className={classes.infoRecognition}>
                <Typography variant="body5" component="h1">
                    {props.typeRecognition}
                </Typography>
                <Typography variant="body3" component="h3">
                    {props.trophyRecognition}
                </Typography>

                {
                    recognitionStart(props.quantityRecognition) == 1 && (
                        <Box>
                            <IconButton className={classes.bronze} />
                        </Box>
                    )
                }
                {
                    recognitionStart(props.quantityRecognition) == 2 && (
                        <Box>
                            <IconButton className={classes.silver} />
                        </Box>

                    )
                }
                {
                    recognitionStart(props.quantityRecognition) == 3 && (
                        <Box>
                            <IconButton className={classes.gold} />
                        </Box>
                    )
                }
            </Box>

            <Box component="fieldset" mb={3} borderColor="transparent">
                {props.quantityRecognition && (
                    recognitionStart(props.quantityRecognition) < 3 && (
                        <PrettoSlider
                            value={props.quantityRecognition - (recognitionStart(props.quantityRecognition) * 5)}
                            aria-labelledby="label"
                            max={5}
                        />
                    )
                )}
            </Box>
        </Card>
    );
}

