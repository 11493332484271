import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CardMainPost from './CardMainPost';
import { dateToShow } from "../../dateToShow";
import { Box, Avatar, DialogContent } from '@material-ui/core';
import env from '../../environments/Api';

const root = env('posts').api.globalRoute;

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#192340'
  },
  icon: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  avatar: {
    width: 120,
    height: 70,
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 2,
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullModalSpecificNotification(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.Open);
  const [reactions, setReactions] = React.useState([]);

  const handleClose = () => {
    props.onModalUpdate(false);
    setOpen(false);

  };

  React.useEffect(() => {
    fetch(`${root}/reacciones`)
      .then(res => (res.ok ? res : Promise.reject(res)))
      .then(res => res.json())
      .then(res => { setReactions(res);})
  }, []);


  return (
    <div >
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" className={classes.icon} color="inherit" aria-label="Account" tooltip="Account" >
              <Avatar
                variant="rounded"
                src={`https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fundes_star_blanco_header_512x512.png`}
                className={classes.avatar} />
            </IconButton >
            <IconButton edge="end" autoFocus color="inherit" onClick={handleClose}>
              <label style={{ fontSize: 'medium' }}>Cerrar</label><CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{
            backgroundColor: '#999999',
            color: '#ffffff'
          }}>
          <Box key={props.post.id_post} p={1} >
            <div id={props.post.id_post} class="post" style={{ background: 'transparent' }}
            >
              <CardMainPost
                key={props.post.idPost}
                userId={props.userId}
                userName={props.userName}
                idOwnerPost={props.post.id_colaborador}
                idPost={props.post.id_post}
                name={props.post.nombre_colaborador}
                date={dateToShow(props.post.diferencia_tiempo_minutos)}
                comment={props.post.descripcion}
                quantityComment={props.post.cantidad_comentarios}
                congratUser={props.post.colaborarador_felicitado}
                typeRecognition={props.post.nombre_reconocimiento}
                imageUrl={props.post.imagen_url_post}
                avatarUrl={props.post.imagen_url_perfil}
                reactions={reactions}
                listReactions={props.post.lista_reacciones}
              />
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}