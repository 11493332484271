import React, { Component, Fragment } from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker, Emoji } from "emoji-mart";
import '../../Reaction.css';
import axios from "axios";
import env from '../../environments/Api';
import { IconButton, Dialog, DialogContent, Button, DialogActions, DialogTitle, List, ListItem, Divider } from "@material-ui/core";


//Esta es la ruta base de las consultas API si se va a probar en deploy cambiar de api.local a api.production
const root = env('posts').api.globalRoute;


class Reaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Emojis seleccionados
      selectedEmojis: [],
      //Los valores de reacciones desde la bd
      customReactionEmojis: [],
      //Muestra el panel de los emojis
      reactionShown: false,
      //Esta lista la voy a crear yo para cargar las reacciones del post la idea es igualarla despues o incluirle los objetos a emojis selected
      reactionListcharged: [],
      //Abrir modal con quienes han reaccionado.
      open: false,
    };
  }

  componentWillReceiveProps() {
    this.setState({ customReactionEmojis: this.props.reactions })
    this.chargeReactions()
  }

  componentDidMount() {
    this.setState({ customReactionEmojis: this.props.reactions })
    this.chargeReactions()
  }

componentWillMount(){
  this.chargeReactions()
}

  async chargeReactions() {
          this.setState({ reactionListcharged: this.props.listReactions })
          let newSelectedEmojis = this.state.reactionListcharged.map(emojiObject => {
            return {
              id: emojiObject.reaccion,
              reaction_count: emojiObject.cantidad_tipo_reaccion
            };
          });
          this.setState({
            selectedEmojis: newSelectedEmojis
          });
  }

  onReactionClick = () => {
    this.setState({
      reactionShown: !this.state.reactionShown
    });
  };

  saveReaction = (emoji) => {
    axios
      .post(`${root}/reacciones`,
        {
          id_post: this.props.idPost,
          reaccion: emoji.id,
          id_colaborador: this.props.userid
        })
      .then(
        res => {
          if (res.status == 200) {
            this.setState({ reactionShown: !this.state.reactionShown })
          }
        }
      )
      .catch(console.log)
  }

  handleEmojiSelect = async emoji => {
    if (emoji.id == "+1") {
      emoji.id = "thumbsup";
    }
    let isEmojiAlreadyFound;
    let emojiObjectWithReactionCount;
    var accept = await axios.get(`${root}/${this.props.idPost}/${this.props.userid}/${emoji.id}/validacion-reacciones`)
    if (accept.data) {
      var deleteEmoji = await axios.delete(`${root}/${this.props.idPost}/${this.props.userid}/${emoji.id}/eliminar-reacciones`)
      if (deleteEmoji) {
        if (emoji.id == "thumbsup") {
          emoji.id = "+1";
        }
        isEmojiAlreadyFound = false;
        let newSelectedEmojis = this.state.selectedEmojis.map(emojiObject => {
          if (emojiObject.id === emoji.id) {
            isEmojiAlreadyFound = true;
            return {
              ...emojiObject,
              reaction_count: emojiObject.reaction_count - 1
            };
          }
          return emojiObject;
        });

        if (isEmojiAlreadyFound) {
          this.setState({
            selectedEmojis: newSelectedEmojis,
            reactionShown: !this.state.reactionShown
          });
        }
      }
    } else {
      this.saveReaction(emoji);
      if (emoji.id == "thumbsup") {
        emoji.id = "+1";
      }
      isEmojiAlreadyFound = false;
      emojiObjectWithReactionCount = { ...emoji, reaction_count: 1 };

      // Recorre el array de emoji y crea el objeto emoji retorno seleccionado y logica al seleccionar el vento que realiza 
      let newSelectedEmojis = this.state.selectedEmojis.map(emojiObject => {
        //Busa el emoji y lo compara aver si es el mismo que ya habia apretado(Esto es lo que va a pasar si ya habia apretado ese emoji)
        if (emojiObject.id === emoji.id) {
          //Set si lo encontro
          isEmojiAlreadyFound = true;
          return {
            //Falta una validacion de solo pueda selecionar una vez
            ...emojiObject,
            reaction_count: emojiObject.reaction_count + 1
          };
        }
        return emojiObject;
      });

      //Si encontro la variable de emoticon arriba entra a este if(Creo que este tambien eso solo si ya hab[ia reaccioado al emoji con anterioridad])
      if (isEmojiAlreadyFound) {
        //Agrega el objeto al estado 
        this.setState({
          selectedEmojis: newSelectedEmojis
        });
        //Cambia valores del objeto y del contador si no lo hab[ia encontrado hasta ahora]
      } else {
        this.setState({
          selectedEmojis: [...newSelectedEmojis, emojiObjectWithReactionCount]
        });
      }
    }
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="reactions" >
        <div className="reactions-counter" >
          {this.state.selectedEmojis.map(emoji => {
            return (
              emoji.reaction_count > 0 && (
                <Fragment key={emoji.id}>
                  <div id={`${emoji.id}`} tabIndex="0">
                    <div>
                      <Emoji emoji={emoji} size={20}
                        onClick={this.handleClickOpen}
                      />{" "}
                      <span style={{ color: 'black', fontSize: 'small' }}>{emoji.reaction_count}</span>
                    </div>
                  </div>
                  <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    scroll={'paper'}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                  >
                    <DialogTitle
                      id="scroll-dialog-title"
                      style={{
                        backgroundColor: '#192340',
                        color: '#ffffff'
                      }}
                    >Reacciones</DialogTitle>
                    <DialogContent
                      id="scroll-dialog-description"
                      tabIndex={-1}
                      style={{
                        backgroundColor: '#999999',
                        color: '#ffffff'
                      }}>
                      <List>
                        {
                          this.state.reactionListcharged.map((reaction) => {
                            return (
                              <div>
                                {<Emoji emoji={reaction.reaccion} size={25} />}
                                {reaction.nombre_colaborador.map(nombre => {
                                  return (
                                    <ListItem key={nombre}>{nombre}</ListItem>)
                                }
                                )
                                }
                                <Divider />
                                <br />
                              </div>
                            )
                          })

                        }
                      </List>
                    </DialogContent>
                    <DialogActions
                      style={{
                        backgroundColor: '#192340',
                        color: '#ffffff'
                      }}
                    >
                      <Button
                        onClick={this.handleClose}
                        style={{
                          backgroundColor: '#0A9DBF',
                          color: '#ffffff'
                        }}
                        variant="text"
                      >
                        Cerrar
                       </Button>
                    </DialogActions>
                  </Dialog>
                </Fragment>
              )
            );
          })}
        </div>
        <div>
          {this.state.reactionShown && (
            <span >
              <Picker
                style={{ position: "relative" }}
                perLine={4}
                showPreview={false}
                showSkinTones={false}
                onSelect={this.handleEmojiSelect}
                include={["custom"]}
                custom={this.props.reactions}
              />
            </span>
          )}
        </div>
        <div>
          {
            this.state.selectedEmojis.length > 0 && (
              <div
                onClick={this.onReactionClick}
                className="reactions-position">
                <IconButton
                  style={{
                    minWidth: 30,
                    minHeight: 30,
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/reaccion_icono_post.svg)`,
                    backgroundPosition: 'center center',
                    backgroundSize: 26,
                  }}
                  className="reaction__counter-value"
                />
                <label style={{ color: 'black', fontSize: 'medium' }}>Me gusta</label>
              </div>
            )
          }
          {
            this.state.selectedEmojis.length == 0 && (
              <div
                onClick={this.onReactionClick}
              >
                <IconButton
                  style={{
                    minWidth: 30,
                    minHeight: 30,
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/reaccion_icono_post.svg)`,
                    backgroundPosition: 'center center',
                    backgroundSize: 26,
                    // position: 'absolute',
                    // marginBottom: 5
                  }}
                  className="reaction__counter-value"
                />
                <label style={{ color: 'black', fontSize: 'medium' }}>Me gusta</label>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Reaction;
