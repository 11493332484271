import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NotificationView from '../components/NotificationView';
import Progress from '../components/ProgressRequest';
import env from '../../environments/Api';
import ReactGA from 'react-ga';

const root = env('posts').api.globalRoute;


class NotificationPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            notifies: [],
            items: 0,
            isFecth: true,
            countPage: 1
        }

    }

    componentDidMount() {
        ReactGA.pageview(`${this.props.location.pathname}`);
        fetch(`${root}/${localStorage.getItem('idCollaborator')}/mostrar-notificaciones`)
            .then(res => res.json())
            .then((data) => {
                this.setState({ notifies: data, isFecth: false })
            })
            .catch(console.log)
    }
    render() {

        return (
            <div
            >
                <Header
                    userMail={localStorage.getItem('emailCollaborator')}
                    userId={localStorage.getItem('idCollaborator')}
                    userName={localStorage.getItem('nameCollaborator')}
                />
                <br />
                <br />
                {
                    this.state.isFecth && (
                        <Progress />
                    )
                }
                <br />
                <br />
                <NotificationView notifies={this.state.notifies}/>
                <br />
                <br />
                <Footer
                    pageSelect={'notification'}
                    userId={localStorage.getItem('idCollaborator')}
                    userName={localStorage.getItem('nameCollaborator')}
                    userMail={localStorage.getItem('emailCollaborator')}
                />
            </div>
        );
    }
}
export default NotificationPage;
