import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles(theme => ({
    fabButton: {
        position: 'fixed',
        bottom: 0,
        right: -50,
        margin: 70,
    },
}));

function FabButtonScrollTop(props) {

    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="flex-end" m={1} p={1} bgcolor="background.paper" zIndex="tooltip" >
            <Box p={1} bgcolor="background.paper">
                <Fab
                    style={{
                        backgroundColor: "#192340"
                    }}
                    aria-label="top"
                    className={classes.fabButton}
                    onClick={() => window.scrollTo(0, 0)}
                ><ExpandLessIcon
                style={{
                    color: "#0A9DBF",
                    fontSize: 40
                }}
                    /> </Fab>
            </Box>
        </Box>
    );
}

export default FabButtonScrollTop;