import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import Reaction from './Reaction';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import $ from "jquery";
import env from '../../environments/Api';
import Tooltip from '@material-ui/core/Tooltip';
import { dateToShow } from "../../dateToShow";


const root = env('posts').api.globalRoute;

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 1000,
    margin: 15,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
      important: true,
      maxWidth: 900,
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: grey[200],
  },
}));


function CardCommentPost(props) {

  const classes = useStyles();

  const removePost = () => {
    axios
      .delete(`${root}/${props.idPost}/${props.userId}/comentarios`)
      .then(res => {
        if (res) {
          $("#" + props.idPost).hide();
          props.onUpdateQuantity();
        }
      })
  }

  function avatarLink() {
    if (props.avatarUrl == null) {
      return "https://storage.googleapis.com/fundes-gestion-talento/imagenes/perfiles/none.jpg"
    } else {
      return props.avatarUrl
    }
  }

  const validatePostUser = (postIdOwner) => {
    if (postIdOwner == props.userId) {
      return true
    } else {
      return false
    }
  }

  return (

    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar
            src={avatarLink()}
            className={classes.avatar} />
        }
        action={
          validatePostUser(props.idOwnerComment) && (
            <Tooltip title="Delete">
              <IconButton aria-label="settings" onClick={removePost}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        }
        title={<strong>{props.name}</strong>}
        subheader={dateToShow(props.date)}
      />
      <CardContent>
        <Typography variant="body2" component="p">
          {props.comment}
        </Typography>

      </CardContent>
      <CardActions>
        <IconButton aria-label="add to favorites">
          <Reaction userid={props.userId} idPost={props.idPost} reactions={props.reactions} listReactions={props.listReactions}/>
        </IconButton>
        {/* <IconButton
          onClick={() => window.alert('happy new year :3 : ' + props.idOwnerComment + ' ' + props.name)}
          // onClick={handleExpandClick}
          aria-label="show more"
        >
          <CreateIcon fontSize="small" />
          Reply
        </IconButton> */}
      </CardActions>
    </Card>
  );
}
export default CardCommentPost;