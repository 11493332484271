import React, { useState, useEffect } from 'react';
import axios from "axios";
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import env from '../../environments/Api';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import { Card, CardHeader, CardContent, Avatar, Container, } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageTransform)

const root = env('posts').api.globalRoute;
const secondRoot = env('dictionarys').api.globalRoute;

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 1500,
        minHeight: 450,
    },
    formControl: {
        minWidth: 100,
        maxWidth: 800,
        padding: 5,
        marginRight: 5
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        backgroundColor: '#0A9DBF',
        color: '#ffffff'
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    imageBackground: {
        minHeight: 50,
    },
    content: {
        padding: 8,
        marginTop: 40,
        alignItems: 'center',
        textAlign: 'center',
    },
    input: {
        border: "3px solid #c3c3c3",
        borderRadius: "4px",
        paddingLeft: 8,
        paddingRight: 8,
        borderBlockColor: '#192340',
        margin: 8,

    },
    button: {
        backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/boton_crear_post.svg)`,
        backgroundPosition: 'center center',
        backgroundSize: 350,
        backgroundRepeat: 'no-repeat',
        minWidth: 200,
        borderRadius: 50,
        color: '#ffffff'
    },
    textField: {
        minWidth: 300,
        maxWidth: 800,
    },
    avatar: {
        width: 140,
        height: 140,
        position: 'absolute',
        borderTopColor: '#fffff',
        top: 90,
        left: 0,
        right: 0,
        margin: 'auto',
        display: 'flex',
        '& > *': {
            margin: theme.spacing(0),
        },
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function CardCreatePost(props) {

    const classes = useStyles();
    const [idRecognition, setIdRecognition] = useState();
    const [message, setMessage] = useState();
    const [listIdBlob, setListIdBlob] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [files, setFiles] = useState([]);
    const [listAllCollaborator, setListAllCollaborator] = useState([]);
    const [listAllRecognition, setListAllRecognition] = useState([]);
    const [listCollaboratorAttachRecognition, setListCollaboratorAttachRecognition] = useState([]);
    const [selectedChip, setSelectedChip] = useState([]);
    const [state, setState] = React.useState({
        open: false,
        Transition: Fade,
        openDrop: false,
    });
    let history = useHistory();

    useEffect(() => {
        axios
            .get(`${root}/${props.userId}/colaboradores`)
            .then(response => setListAllCollaborator(response.data));
    }, []);

    useEffect(() => {
        axios
            .get(`${secondRoot}`)
            .then(response => setListAllRecognition(response.data));
    }, []);

    const toRedirect = () => {
        const timer = setTimeout(() => {
            setRedirect(true)
          }, 2800);
          return () => clearTimeout(timer);
    }

    function onSubmit() {
        if (message == null || idRecognition == null || listCollaboratorAttachRecognition.length <= 0) {
            setState({
                open: true,
                Transition: SlideTransition,
                message: "Debes completar todos los campos para realizar el reconocimiento.",
            });
        } else {
            const listIdCollaboratorAttachRecognition = listCollaboratorAttachRecognition.map((colab) => colab.id_colaborador);
            axios
                .post(`${root}`,
                    {
                        id_colaborador: props.userId,
                        descripcion: message,
                        lista_colaboradores: listIdCollaboratorAttachRecognition,
                        id_tipo_reconocimiento: idRecognition,
                        lista_id_blobs: listIdBlob
                    })
                .then(
                    res => {
                        if (res.status == 200) {
                            setState({
                                open: true,
                                Transition: SlideTransition,
                                message: "Post realizado con exito 🎉.",
                            });
                            toRedirect();
                        }
                    }
                )
                .catch(console.log);
        }
    }

    const handleChange = event => {
        setListCollaboratorAttachRecognition(event.target.value);
        setState({ openDrop: !state.openDrop });
    };

    const handleOnClick = () => {
        setState({ openDrop: !state.openDrop });
    };

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };
    return (
        <Container justify="center"
            alignItems="center">
            <Card className={classes.card}>
                <CardHeader
                    className={classes.imageBackground}
                    avatar={
                        <Avatar
                            variant="rounded"
                            src={`https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fundes_star_color_icono_post_icono_crear_post_512x512.png`}
                            className={classes.avatar}
                        />
                    }
                />
                <CardContent
                    className={classes.content}
                >
                    <div id="form-create-post" class="Form-Create-Post" >
                        <Snackbar
                            open={state.open}
                            autoHideDuration={2500}
                            onClose={handleClose}
                            TransitionComponent={state.Transition}
                            message={state.message}
                        />
                        {
                            redirect ? (history.push('/fundesstar/home')) : ''
                        }
                        <FormControl className={classes.formControl}>
                            <Hidden>
                                <label>¡Felicidades!</label>
                            </Hidden>
                            <Hidden>
                                <div className={classes.chips}>
                                    {selectedChip.map(value => (
                                        <Chip key={value}
                                            label={value.nombre_completo}
                                            className={classes.chip}
                                            onDelete={() => {
                                                var index = selectedChip.indexOf(value);
                                                if (index > 0) {
                                                    setSelectedChip(selectedChip.splice(index, 1));
                                                }
                                                else if (index == 0) {
                                                    setSelectedChip(selectedChip.splice(index, 1));
                                                    setSelectedChip([]);
                                                }
                                            }
                                            }
                                        />
                                    ))}
                                </div>
                                <Select
                                    multiple
                                    value={listCollaboratorAttachRecognition}
                                    onChange={handleChange}
                                    open={state.openDrop}
                                    onClick={handleOnClick}
                                    input={
                                        <Input
                                            className={classes.input}
                                            disableUnderline
                                        />
                                    }
                                    renderValue={selected => (
                                        setSelectedChip(selected)
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {listAllCollaborator.map(colab => (
                                        colab.id_colaborador != props.userId && (
                                            <MenuItem key={colab.id_colaborador} value={colab}>
                                                {colab.nombre_completo}
                                            </MenuItem>
                                        )))}
                                </Select>
                            </Hidden>
                            <br />
                            <Hidden >
                                <label> Te reconocemos en: </label>
                            </Hidden>
                            <Hidden >
                                <Select native defaultValue="" onChange={(e) =>
                                    setIdRecognition((e.target.value))}
                                    input={
                                        <Input
                                            className={classes.input}
                                            disableUnderline
                                            id="glossary" />
                                    }
                                >
                                    <option value="Seleccione un reconocimiento" />
                                    {listAllRecognition.map((rec) => (
                                        <option value={rec.id_tipo_reconocimiento} >{rec.nombre}</option>
                                    ))}
                                </Select>
                            </Hidden>
                            <br />
                            <Grid container justify="center" className={classes.textField}>
                                <TextField
                                    fullWidth={true}
                                    id="outlined-multiline-static"
                                    name="message"
                                    placeholder="Escribe un comentario"
                                    multiline
                                    rows="4"
                                    variant="outlined"
                                    labelWidth={100}
                                    onChange={(e) =>
                                        setMessage((e.target.value))}
                                />
                            </Grid>
                            <br />
                            <div>
                                <FilePond
                                    files={files}
                                    maxFiles={1}
                                    onupdatefiles={setFiles}
                                    labelIdle='<span style="color: #0A9DBF" class="filepond--label-action">Adjuntar imagen</span>'
                                    server={{
                                        url: `${root}/almacenar-archivos`,
                                        process: {
                                            onload: (res) => {
                                              //  setListIdBlob(res)
                                              listIdBlob.push(res)
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <Grid container justify="center">
                                    <Button
                                        className={classes.button}
                                        onClick={() => onSubmit()}
                                        variant="text"
                                    >Crear</Button>
                                </Grid>
                            </div>
                        </FormControl>
                    </div>
                </CardContent>
            </Card>
        </ Container>
    );
}

export default CardCreatePost;