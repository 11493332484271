import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import Cookies from 'universal-cookie';
import Container from '@material-ui/core/Container';
import axios from "axios";
import './Login.css';
import env from '../../environments/Api';
import { Card, CardContent, IconButton, CardHeader, Avatar } from '@material-ui/core';
import ReactGA from 'react-ga';

// ReactGA.initialize('UA-159149691-1');
// ReactGA.pageview(`${window.location}`);
// ReactGA.pageview(${window.location});

const root = env('logs').api.globalRoute;
const rootPost = env('posts').api.globalRoute;

const cookie = new Cookies();
var idToken;
var emailUser;

let divStyle = {
  height: '100vh',
  backgroundImage: "url(https://www.theuiaa.org/wp-content/uploads/2017/12/2018_banner.jpg)",
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idCollaborator: '',
      fullName: '',
      avatarImage: '',
      email: '',
      requestId: false,
    }
  }

  componentDidMount(){
    ReactGA.pageview(`${this.props.location.pathname}`);
  }

  async requestColaboradorId(props) {
    await axios
      .get(`${root}/${this.state.email}/`)//Aqui pido el id colaborador de aqui en adelnate puedo hacer el post del onesignal.
      .then(res => {
        if (res.status == 200 && Number.isInteger(res.data)) {//Aqui comienza la peticion del almacenar el idone signal esto me lo tengo que llevar para despues de preguntar si quiere permitir notficaciones
          var OneSignal = window.OneSignal || [];
          OneSignal.getUserId(function (userId) {
            axios
              .post(`${root}/almacenar-push-tokens`, {
                id_colaborador: res.data,
                token: userId,
              })
              .then(res => {
                if (res.status == 200) {
                  // window.alert("Almacene el Id OneSignal de usuario");
                }
              })
          });
          const data = res.data
          this.setState({ idCollaborator: data, requestId: true })
          localStorage.setItem('idCollaborator', data)
          fetch("https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" + idToken)
            .then((data) => {
              if (data.status == 200) {
                if (emailUser.includes('@fundes.org')) {
                  cookie.set('_idToken', idToken, {});
                  axios
                    .post(`${rootPost}/almacenar-imagen-perfiles`, {
                      email: this.state.email,
                      url: this.state.avatarImage
                    })
                    ReactGA.event({
                      category: 'Login',
                      action: 'Ingreso satisfactorio a FUNDES Star',
                      label: 'Login.js'
                    });
                    // .then(response => console.log(response))
                  this.props.history.push('/');
                } else {
                  alert('La cuenta no es admitida.');
                }
              } else {
                window.alert('No se pudo ingresar');
              }
            })
            .catch(console.log)
        } else if (res.status == 200 && res.data == false) {
          window.alert("usuario no encontrado");
        } else {
          window.alert("Error Inesperado");
        }
      })
      .catch((res) => {
        window.alert("Error de conexión");
      })
  }

  render() {

    const responseGoogle = (response) => {
      if (response == undefined) {
        alert('No se encuentra el token recibido')
      } else {
        ReactGA.event({
          category: 'Login',
          action: 'Intento de ingreso a FUNDES Star',
          label: 'Login.js'
        });

        idToken = response.tokenId;
        emailUser = response.profileObj.email;
        localStorage.setItem('emailCollaborator', response.profileObj.email);
        localStorage.setItem('tokenCollaborator', idToken);
        localStorage.setItem('nameCollaborator', response.profileObj.name);
        this.setState({ fullName: response.profileObj.name });
        this.setState({ avatarImage: response.profileObj.imageUrl });
        this.setState({ email: response.profileObj.email });
        this.requestColaboradorId(response = { response })
      }
    }

    return (
      <Container
        style={{
          backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/patron_card_perfil.jpg)`,
          color: 'white',
          backgroundSize: 'cover',
          minHeight: 260,
          paddingTop: 20,
          paddingBottom: 80

        }}>
        <Card style={{
          maxWidth: 1500,
          minHeight: 450,
          padding: 6,
        }}>
          <CardHeader
            style={{
              marginTop: 50,
              margin: 'auto',
              alignItems: 'center',
              textAlign: 'center',
              backgroundColor: '#fffff',
              minHeight: 150,
            }}
            avatar={
              <Avatar
                style={{
                  width: 150,
                  height: 150,
                  position: 'absolute',
                  top: 50,
                  left: 0,
                  right: 0,
                  margin: 'auto',
                  display: 'flex',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/manifiesto_270x270.png)`,
                  backgroundPosition: 'center center',
                  backgroundSize: 150,
                  backgroundColor: '#FFFFFF'
                }}
              />
            }
          />
          <CardContent style={{
            padding: 5,
            minHeight: 300,
            backgroundColor: "#0A9DBF",
            marginTop: 50,
            margin: 'auto',
            alignItems: 'center',
            textAlign: 'center',
          }}>
            <GoogleLogin
              clientId="1082237584033-n6u66fu5rrpelol1facmp18vp9nv8n5o.apps.googleusercontent.com"
              buttonText="Ingresar"
              onSuccess={responseGoogle}
              // onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </CardContent>
        </Card>
      </Container>
    );
  }
}

export default Login;