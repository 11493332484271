import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { dateToShow } from "../../dateToShow";
import axios from 'axios';
import FullModalSpecificNotification from './FullModalSpecificNotification';
import env from '../../environments/Api';

const root = env('posts').api.globalRoute;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  info: {
    marginTop: 50,
    position: 'static',
    margin: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

export default function InsetDividers(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [post, setPost] = useState([]);

  const updateOpen = (value) => {
    setOpen(value);
  }

  function openModalCard(value) {
    axios
      .get(`${root}/posts/${value}/notificaciones`)
      .then(res => {
        if (res.status == 200) {
          setPost(res.data)
          setOpen(true)
        }
      }
      )
  }

  return (
    <Card>
      <CardHeader
        title="NOTIFICACIONES"
      />
      <CardContent>
        {
          props.notifies && (
            <List className={classes.root}>
              {
                props.notifies.map(notify => {
                  return (
                    <div>
                      <ListItem>
                        {
                          notify.tipo_post == 'FLCTR' && (
                            <React.Fragment>
                              <ListItemText
                                primary={`¡Felicidades ${notify.nombre_felicitado}! 🎉`}
                                secondary={`Has recibido un FUNDES Star por tu labor en ${notify.nombre_reconocimiento}.`}
                                onClick={(e) => { openModalCard(notify.id_post) }}
                              />
                              <p edge="end">{`${dateToShow(notify.diferencia_tiempo_minutos)}`}</p>
                            </React.Fragment>
                          )
                        }
                        {
                          notify.tipo_post == 'RSPSA' && (
                            <React.Fragment>
                              <ListItemText
                                primary={`¡Han comentado tu post! 🎉`}
                                secondary={`${notify.nombre_realizado} ha comentado tu post.`}
                                onClick={(e) => { openModalCard(notify.id_post_vinculado) }}
                              />
                              <p edge="end">{`${dateToShow(notify.diferencia_tiempo_minutos)}`}</p>
                            </React.Fragment>
                          )
                        }
                      </ListItem>
                      <Divider />
                    </div>
                  )
                })
              }
            </List>
          )
        }
      </CardContent>
      <CardContent className={classes.info}>
        {
          !props.notifies && (
            <React.Fragment>
              <div>
                Aún no cuentas con
       </div>
              <div>
                <img src="https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fundes_star_color_icono_post_icono_crear_post_512x512.png" alt="" width="100"></img>
              </div>
            </React.Fragment>
          )
        }
      </CardContent>
      <CardContent>
        {
          open && (
            console.log(post),
            <FullModalSpecificNotification
              Open={open}
              userId={localStorage.getItem('idCollaborator')}
              userName={localStorage.getItem('nameCollaborator')}
              post={post}
              onModalUpdate={updateOpen}
            />
          )
        }

      </CardContent>
    </Card>
  );
}
