import React, { Component } from "react";
import { Button } from "@material-ui/core";
import {Link} from 'react-router-dom';

class WelcomePage extends Component {

  // componentDidMount() {
  // }


  render() {
    return (
      <div>
        <nav className="navbar navbar">
          <ul className="nav">
            <li>
              <Button
               component={Link} 
               to={'/fundesstar'}>FundesStar</Button>
            </li>
            <li>
              <Button>Cerrar Sesion por Comleto</Button>
              {/* <Link to="/fundesstar">FundesStar</Link> */}
            </li>
            <li>
              {/* <Link to="/products">Products</Link> */}
            </li>
            <li>
              {/* <Link to="/admin">Admin area</Link> */}
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}


export default WelcomePage;